import React from 'react'
import styles from './footer.module.less'
import { useTranslation } from 'react-i18next'
import { Row, Col, message } from 'antd'
import { NavLink } from 'react-router-dom'
import logo from '~/images/footer/logo.png'
import officialCode from '~/images/footer/official-account.png'
import copy from 'copy-to-clipboard'

const Footer: React.FC<any> = () => {
    const { t } = useTranslation()

    message.config({
        top: 150,
        duration: 2,
        maxCount: 3,
        rtl: true,
    })

    const onTextClick = (text) => {
        copy(t(text))
        message.success(t('内容已复制'))
    }

    const handleOpenIPC = () => {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }

    return (
        <div className={styles.footer}>
            <div className={styles.height46}></div>
            <Row className={styles.logo}>
                <Col span={6} offset={4}>
                    <img src={logo} />
                    <div className={styles.companyName} onClick={() => onTextClick('北京英飞智药科技有限公司')}>
                        {t('北京英飞智药科技有限公司')}
                    </div>
                </Col>
            </Row>
            <Row className={styles.companyInfo} gutter={[0, 24]}>
                <Col span={4} xs={16} sm={9} offset={4}>
                    <div className={styles.infoTitle}>{t('联系我们')}</div>
                    <div
                        className={styles.infoItem}
                        onClick={() => onTextClick('北京市朝阳区容创路望京生命科学园2号楼5层501')}
                    >
                        {t('办公地址：北京市朝阳区容创路望京生命科学园2号楼5层501')}
                    </div>
                    <div className={styles.infoItem} onClick={() => onTextClick('+86 18518416618')}>
                        {t('联系方式：+86 18518416618')}
                    </div>
                    <div className={styles.infoItem} onClick={() => onTextClick('bd@iipharma.cn')}>
                        {t('项目合作：bd@iipharma.cn')}
                    </div>
                    <div className={styles.infoItem} onClick={() => onTextClick('hr@iipharma.cn')}>
                        {t('求职招聘：hr@iipharma.cn')}
                    </div>
                    <div className={styles.infoItem} onClick={() => onTextClick('service@iipharma.cn')}>
                        {t('其他业务：service@iipharma.cn')}
                    </div>
                </Col>
                <Col span={4} xs={8} sm={4} offset={1}>
                    <div className={styles.infoTitle}>{t('关于我们')}</div>
                    <NavLink to='/home' className={`${styles.infoItem} ${styles.link}`}>
                        {t('首页')}
                    </NavLink>
                    <NavLink to='/company' className={`${styles.infoItem} ${styles.link}`}>
                        {t('公司简介')}
                    </NavLink>
                    <NavLink to='/product' className={`${styles.infoItem} ${styles.link}`}>
                        {t('产品介绍')}
                    </NavLink>
                    {/* <NavLink to='/technology' className={`${styles.infoItem} ${styles.link}`}>
                        {t('技术介绍')}
                    </NavLink> */}
                    <NavLink to='/news' className={`${styles.infoItem} ${styles.link}`}>
                        {t('新闻动态')}
                    </NavLink>
                    <NavLink to='/contact-us' className={`${styles.infoItem} ${styles.link}`}>
                        {t('联系我们')}
                    </NavLink>
                </Col>
                <Col span={4} xs={8} sm={4} offset={2}>
                    <div className={styles.infoTitle}>{t('关注我们')}</div>
                    <div className={styles.officialCode}>
                        <img src={officialCode} />
                    </div>
                </Col>
            </Row>
            <div style={{textAlign: 'center'}}>
                <div className={styles.recordNo}>
                    <div>{t(`Copyright@2019-${new Date().getFullYear()} 英飞智药  版权所有`)}</div>
                    <div onClick={handleOpenIPC}>{t('京ICP备19044654')}</div>
                </div>
            </div>
        </div>
    )
}

export default Footer
