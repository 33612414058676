import React, { useEffect } from 'react'
import styles from './pipeline.module.less'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { goToTop } from '@/utils'

const PipelinePage: React.FC<any> = () => {
    const { t } = useTranslation()

    useEffect(() => {
        goToTop()
    }, [])

    return (
        <>
            <div className={styles.pipeline}>
                <div className={styles.height200}></div>
                <div className={styles.pipelineTitle}>{t('Pipeline R & D')}</div>
            </div>
            <div className={styles.pipelineWrapper}>
                <div className={styles.pipelineHeader}>
                    <div className={styles.headerText}>
                        <span className={styles.program}>{t('PROGRAM')}</span>
                        <span className={styles.potentialIndication}>{t('POTENTIAL INDICATION')}</span>
                        <span className={styles.research}>{t('RESEARCH')}</span>
                        <span className={styles.leadOptimization}>{t('LEAD OPTIMIZATION')}</span>
                        <span className={styles.indEnabling}>{t('IND ENABLING')}</span>
                    </div>
                </div>
                <div className={styles.table}>
                    <div className={styles.row} style={{ background: '#43A89A' }}>
                        <span>{t('Anti infective drugs')}</span>
                    </div>
                    <div className={styles.tr}>
                        <div className={`${styles.td} ${styles.width360}`}></div>
                        <div className={`${styles.td} ${styles.width148}`}>
                            <span style={{ color: '#43A89A' }}>{t('IIP006C')}</span>
                        </div>
                        <div className={`${styles.td} ${styles.width390}`}>
                            <span style={{ color: '#3D3D3D', fontWeight: 500 }}>{t('Anti-Fungal')}</span>
                        </div>
                        <div className={`${styles.td} ${styles.width220}`}>
                            <div className={`${styles.prossesFull}`}></div>
                        </div>
                        <div className={`${styles.td} ${styles.width220}`}></div>
                        <div className={`${styles.td} ${styles.width220}`}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PipelinePage
