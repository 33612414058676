import React, { useEffect, useState } from 'react'
import { Tooltip, Dropdown, Menu, message } from 'antd'
import styles from './header.module.less'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '~/images/header/logo.png'
import logoGreen from '~/images/header/logo-green.png'
import qrcode from '~/images/header/qrcode.png'
import downloadIcon from '~/images/header/download.png'
import downloadWhiteIcon from '~/images/header/download-white.png'
import downloadGreenIcon from '~/images/header/download-green.png'
import { goToTop } from '@/utils'
import useWindowSize from '@/hooks/windowSize/Index'
import { MenuOutlined } from '@ant-design/icons'
import { lngs } from '@/i18n'
import copy from 'copy-to-clipboard'

const Header: React.FC<any> = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { width, height } = useWindowSize()
    const [collapse, setCollapse] = useState<boolean>(false)
    const [isTop, setIsTop] = useState(true)
    const [downloadIconSrc, setDownloadIconSrc] = useState(downloadIcon)
    const [visible, setVisible] = useState(false)
    // const [menuActive, setMenuActive] = useState(false) // 只用来判断是否hover在menu上（产品介绍）
    let menuActive = false // 不使用state，因为state会更新dom，导致menu消失

    useEffect(() => {
        setCollapse(width < 768)
    }, [width])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            // 关闭页头展开菜单
            setVisible(false)
            // 根据距顶部高度,切换样式
            if (window.scrollY > 0) {
                setDownloadIconSrc(downloadGreenIcon)
                setIsTop(false)
            } else {
                setDownloadIconSrc(downloadIcon)
                setIsTop(true)
            }
        })
        return () => {
            window.removeEventListener('scroll', () => {})
        }
    }, [])

    const onTextClick = (text) => {
        copy(text)
        message.success(t('内容已复制'))
    }

    const onDownloadClick = () => {
        navigate('/pharma-mind')
        setTimeout(() => {
            document.getElementById('download').scrollIntoView()
        }, 100)
    }
    const goHome = () => {
        navigate('/home')
    }

    const onMouseEnter = () => {
        if (isTop) {
            setDownloadIconSrc(downloadWhiteIcon)
        } else {
            setDownloadIconSrc(downloadIcon)
        }
    }
    const onMouseLeave = () => {
        if (isTop) {
            setDownloadIconSrc(downloadIcon)
        } else {
            setDownloadIconSrc(downloadGreenIcon)
        }
    }

    const MP = () => (
        <div className={styles.mpQrcode}>
            <div className={styles.qrcodeTitle}>{t('PharmaMind小程序')}</div>
            <div className={styles.qrcodeDesc}>{t('微信扫一扫，免费使用小程序')}</div>
            <img src={qrcode} />
        </div>
    )
    const Tel = () => (
        <div className={styles.telphone}>
            <div className={styles.telphoneTitle}>{t('联系电话')}</div>
            <div
                className={styles.telphoneNumber}
                onClick={() => {
                    onTextClick('+86 18518416618')
                }}
            >
                +86 18518416618
            </div>
        </div>
    )

    const GlobalNav = () => (
        <div className={styles.globalNav}>
            <Tooltip placement='left' color='#fff' overlayStyle={{ maxWidth: 400 }} title={<MP />}>
                <div className={`${styles.navItem}`}>
                    <div className={`${styles.mp}`}>
                        {/* <span className={`${styles.navItemText}`}>{t('小程序')}</span> */}
                    </div>
                </div>
            </Tooltip>

            <Tooltip placement='left' color='#fff' overlayStyle={{ maxWidth: 400 }} title={<Tel />}>
                <div className={`${styles.navItem}`}>
                    <div className={`${styles.tel}`}>
                        {/* <span className={`${styles.navItemText}`}>{t('联系电话')}</span> */}
                    </div>
                </div>
            </Tooltip>
            <div className={`${styles.navTop}`} onClick={goToTop}>
                <div className={`${styles.top}`}></div>
            </div>
        </div>
    )

    const mobileProductMenu = [
        <NavLink
            key={2}
            to='/pharma-mind'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('PharmaMind')}
        </NavLink>,
        <NavLink
            key={2}
            to='/top-targets'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('TopTargets')}
        </NavLink>,
    ]

    const menus = () => (
        <Menu items={[
            {
                key: '1',
                label: (
                    <NavLink key={1} to='/pharma-mind' className={styles.menuTextBlack}>
                        {t('PharmaMind®')}
                    </NavLink>
                ),
            },
            {
                key: '2',
                label: (
                    <NavLink key={1} to='/top-targets' className={styles.menuTextBlack}>
                        {t('TopTargets®')}
                    </NavLink>
                ),
            },
        ]} />
    )

    const pcProductMenu = [
        <Dropdown overlay={ menus } placement='bottom'>
            <div
                onMouseEnter={() => {
                    menuActive = true
                }}
                onMouseLeave={() => {
                    menuActive = false
                }}
                className={
                    menuActive
                        ? isTop
                            ? styles.textBlackActive
                            : styles.textWhiteActive
                        : isTop
                        ? styles.textBlack
                        : styles.textWhite
                }
            >
                {t('产品介绍')}
            </div>
        </Dropdown>,
    ]

    const productMenu = collapse ? mobileProductMenu : pcProductMenu

    const Navs = [
        <NavLink
            key={0}
            to='/home'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('首页')}
        </NavLink>,
        <NavLink
            key={1}
            to='/company'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('公司简介')}
        </NavLink>,
        ...productMenu,
        <NavLink
            key={3}
            to='/technical'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('技术服务')}
        </NavLink>,
        <NavLink
            key={4}
            to='/pipeline'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('研发管线')}
        </NavLink>,
        <NavLink
            key={5}
            to='/news'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('新闻动态')}
        </NavLink>,
        <NavLink
            key={6}
            to='/contact-us'
            className={({ isActive }) =>
                isActive
                    ? isTop
                        ? styles.textBlackActive
                        : styles.textWhiteActive
                    : isTop
                    ? styles.textBlack
                    : styles.textWhite
            }
        >
            {t('联系我们')}
        </NavLink>,
    ]

    const menu = () => {
        const items = Navs.map((item, index) => {
            return {
                label: item,
                key: index,
            }
        })
        return (
            <Menu
                items={items}
                onClick={() => {
                    setVisible(false)
                }}
            />
        )
    }

    const getLang = () => {
        const lang = i18n.language
        if (lang === lngs['en-US'].value) {
            return lngs['zh-CN']
        } else if (lang === 'zh-CN') {
            return lngs['en-US']
        } else {
            return lngs['en-US']
        }
    }

    const handleChangeLang = () => {
        const lang = getLang()
        i18n.changeLanguage(lang.value)
    }

    const LanguageSelector = (props: any) => (
        <div
            className={isTop ? styles.textBlack : styles.textWhite}
            onClick={handleChangeLang}
            style={{ ...props.customStyle }}
        >
            {getLang().name}
        </div>
    )

    const HeaderRight = () => (
        <div className={styles.right}>
            {Navs}
            <LanguageSelector />
            <div
                className={isTop ? styles.download : styles.downloadBlack}
                onClick={onDownloadClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <img src={downloadIconSrc} />
                <span>Download</span>
            </div>
        </div>
    )
    return (
        <nav className={`${styles.header} ${isTop ? styles.headerBlack : styles.headerWhite}`}>
            <GlobalNav />
            <div
                className={styles.left}
                onClick={() => {
                    goHome()
                }}
            >
                <img className={styles.logo} src={isTop ? logo : logoGreen} />
            </div>
            {!collapse && <HeaderRight />}
            {collapse && (
                <>
                    <LanguageSelector
                        customStyle={{
                            position: 'absolute',
                            right: '48PX',
                            display: 'flex',
                            alignItems: 'center',
                            margin: 0,
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            right: '16PX',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onMouseLeave={() => {
                            setVisible(false)
                        }}
                    >
                        <Dropdown
                            overlay={menu}
                            visible={visible}
                            overlayStyle={{ width: '160PX', textAlign: 'center' }}
                            trigger={['click']}
                        >
                            <MenuOutlined
                                className={styles.headerIcon}
                                style={{ color: isTop ? '#fff' : '#40A99A' }}
                                onClick={() => {
                                    setVisible(!visible)
                                }}
                            />
                        </Dropdown>
                    </div>
                </>
            )}
        </nav>
    )
}

export default Header
