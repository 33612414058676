import React, { useEffect } from 'react'
import { Layout } from 'antd'
import { useLocation } from 'react-router-dom'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Header from '../header/Index'
import Footer from '../footer/Index'
import styles from './frame.module.less'
import { addVisitLog } from '@/api/index'

const Frame: React.FC<any> = (props) => {
    const location = useLocation()

    //获取设备唯一标识
    const getUniqueCode = async () => {
        return new Promise((res, rej) => {
            FingerprintJS.load().then((fp) => {
                fp.get().then((result) => {
                    localStorage.setItem('uuid', result.visitorId)
                    localStorage.setItem('agentDetail', JSON.stringify(result || {}))
                    res(result)
                }, (err) => {
                    rej(err)
                })
            })
        })
    }
    useEffect(() => {
        const foo = async () => {
            let agentDetail: any = localStorage.getItem('agentDetail')
            if(!agentDetail) {
                agentDetail = await getUniqueCode()
            }
            agentDetail = JSON.parse(agentDetail)
            const { components }: any = agentDetail
            let data: any = {
                uuid: agentDetail?.visitorId,
                agent_detail: {
                    languages: components?.languages?.value,
                    screenResolution: components?.screenResolution?.value,
                    timezone: components?.timezone?.value,
                    touchSupport: components?.touchSupport?.value,
                },
                path: window.location.href.split('?')[0],
                
            }
            const urlParmas = new URLSearchParams(location.search)
            const from = urlParmas.get('from')
            if(from) {
                data.come_from = from
            }
            addVisitLog(data)
        }
        foo()
    }, [location])

    return (
        <Layout>
            <Layout.Header className={styles.header}>
                <Header />
            </Layout.Header>
            <Layout.Content>{props.children}</Layout.Content>
            <Layout.Footer className={styles.footer}>
                <Footer />
            </Layout.Footer>
        </Layout>
    )
}

export default Frame
