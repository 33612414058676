import axios from 'axios'

const isProd = import.meta.env.PROD

const instance = axios.create({
    baseURL: isProd?  'https://www-backend.iipharma.cn': '',
    timeout: 10000,
    headers: {
        'website-api-guard': '14398c9520fbadf30451e29e9f31aaa4'
    }
})

export const addVisitLog = (data: any) => {
    instance.post('/api/v1/f/visit', data)
}
export const sendContact = (data: any) => {
    instance.post('/api/v1/f/contact', data)
}