import React from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import pharmaMind from '~/images/home/pharma-mind.png'
import topTargets from '~/images/home/top-targets.png'
import mac from '~/images/home/mac-white.png'
import win from '~/images/home/win-white.png'
import { addVisitLog } from '@/api/index'

const ProductPage: React.FC<any> = () => {
    const { t } = useTranslation()

    const downloadMac = () => {
        const url = import.meta.env.VITE_DOWNLOAD_MAC_URL
        download(url)
    }
    const downloadWin = () => {
        const url = import.meta.env.VITE_DOWNLOAD_WIN_URL
        download(url)
    }
    const download = (url: string) => {
        addDownloadLogs(url)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.click()
    }

    const addDownloadLogs = (url: string) => {
        let agentDetail: any = localStorage.getItem('agentDetail')
        agentDetail = JSON.parse(agentDetail)
        const { components }: any = agentDetail
        let data: any = {
            uuid: agentDetail?.visitorId,
            agent_detail: {
                languages: components?.languages?.value,
                screenResolution: components?.screenResolution?.value,
                timezone: components?.timezone?.value,
                touchSupport: components?.touchSupport?.value,
            },
            path: url,
        }
        const urlParmas = new URLSearchParams(location.search)
        const from = urlParmas.get('from')
        if (from) {
            data.come_from = from
        }
        addVisitLog(data)
    }

    const navigate = useNavigate()

    const handleClickPharmaMind = () => {
        navigate('/pharma-mind')
    }

    const handleClickTopTargets = () => {
        navigate('/top-targets')
    }
    return (
        <div className={styles.pharmamindBg}>
            <div className={styles.height80}></div>
            <div className={styles.productTitle}>{t('平台介绍')}</div>
            <div className={styles.pharmamind}>
                <div className={`${styles.left}`} onClick={handleClickPharmaMind}>
                    <div className={styles.pharmamindTitle}>{t('PharmaMind®')}</div>
                    <div className={styles.pharmamindDesc}>
                        <div>{t('一站式分子设计与评估计算平台——PharmaMind®')}</div>
                        <ul>
                            <li>{t('针对不同来源的数据资料进行标准化的清洗与对齐流程')}</li>
                            <li>
                                {t(
                                    '针对高质量数据，充分利用人工智能、计算机辅助药物设计、生物与药物信息学等前沿的技术，开发高质量计算模型'
                                )}
                            </li>
                            <li>{t('灵活运用与迭代高质量模型，针对靶点结构或已知配体进行的高精度分子设计')}</li>
                            <li>
                                {`${t('已对外开放模块：')} ${t('MolMiner（分子图像识别）')}${t('、')}${t(
                                    'MolBuilder（基于结构的三维分子生成）'
                                )}${t('、')}${t('PharmGPT（基于药效团的二维分子生成）')}${t('、')}${t(
                                    'MolEVA（分子基础性质预测）'
                                )}`}
                            </li>
                            {/* <li>
                                {t('已对外开放模块：')}
                                <ul>
                                    <li>{t('MolMiner（分子图像识别）')}</li>
                                    <li>{t('MolBuilder（基于结构的三维分子生成）')}</li>
                                    <li>{t('PharmGPT（基于药效团的二维分子生成）')}</li>
                                    <li>{t('MolEVA（分子基础性质预测）')}</li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                    <div className={styles.pharmamindImg}>
                        <img src={pharmaMind} />
                    </div>
                    {/* <div className={`${styles.pharmamindMore} ${styles.scaleAnim}`} onClick={handleClick}>
                        <span>{t('More')}</span>
                    </div> */}
                </div>
                <div className={`${styles.right}`} onClick={handleClickTopTargets}>
                    <div className={styles.pharmamindTitle}>{t('TopTargets®')}</div>
                    <div className={styles.pharmamindDesc}>
                        <div>{t('靶点发现与评价计算平台——TopTargets®')}</div>
                        <ul>
                            <li>{t('由临床需求出发，从系统生物学角度关注疾病及其关联网络')}</li>
                            <li>
                                {t('探索生物分子间的相互作用，以全面地理解疾病发生机制，保证靶点发现的合理性与有效性')}
                            </li>
                            <li>{t('以疾病-靶点-化合物为中心的生物疾病知识图谱')}</li>
                            <li>
                                {t(
                                    '通过生物疾病网络、蛋白序列与结构、化合物等多层次人工智能信息挖掘技术来评价潜在的靶点'
                                )}
                            </li>
                            <li>{t('结合市场需求形成靶点的可开发性评价报告')}</li>
                        </ul>
                    </div>
                    <div className={styles.pharmamindImg}>
                        <img src={topTargets} />
                    </div>
                    {/* <div className={`${styles.pharmamindMore} ${styles.scaleAnim}`} onClick={handleClick}>
                        <span>{t('More')}</span>
                    </div> */}
                </div>
            </div>
            <div className={`${styles.pharmamindDownload} wow fadeInUp`}>
                <div className={`${styles.mac} ${styles.scaleAnim}`} onClick={downloadMac}>
                    <img src={mac} />
                    <span>{t('Mac版本下载')}</span>
                </div>
                <div className={styles.line}></div>
                <div className={`${styles.windows} ${styles.scaleAnim}`} onClick={downloadWin}>
                    <img src={win} />
                    <span>{t('Windows版本下载')}</span>
                </div>
            </div>
        </div>
    )
}

export default ProductPage
