import React from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const GeneralPage: React.FC<any> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/company')
    }

    return (
        <div className={styles.general}>
            <div className={styles.height300}></div>
            <div className={`${styles.title}`}>
                {t('Infinite Intelligence Pharma')}
            </div>
            <div className={styles.slogan}>{t('Insight & Innovation for Drug Dicovery')}</div>
            <div className={`${styles.more} animated`} onClick={handleClick}>
                <span>{t('More')}</span>
            </div>
        </div>
    )
}

export default GeneralPage
