import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'
import LanguageDetector from 'i18next-browser-languagedetector'
import resources from './locales/resources'

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const lngs: any = {
  'zh-CN': {
    name: '中文',
    value: 'zh-CN',
    antd: zhCN,
  },
  'en-US': {
    name: 'EN',
    value: 'en-US',
    antd: enUS,
  },
}

const getLngName = (lng: string) => {
  const lngItem = lngs[lng]
  return lngItem ? lngItem.name : ''
}

// 初始化
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    debug: false,
    resources: resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // lng: localStorage.getItem('i18nextLng') || 'en-US',
  })

export default i18n
export { lngs, getLngName }
