import en from './en.json'
import zh from './zh.json'

export default {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
}
