import React, { useEffect, useState } from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'antd'
import partners1 from '~/images/home/partners-1.png'
import partners2 from '~/images/home/partners-2.png'
import partners3 from '~/images/home/partners-3.png'
import partners4 from '~/images/home/partners-4.png'
import partners5 from '~/images/home/partners-5.png'
import partners6 from '~/images/home/partners-6.png'
import partners7 from '~/images/home/partners-7.png'
import partners8 from '~/images/home/partners-8.png'
import partners9 from '~/images/home/partners-9.png'
import partners10 from '~/images/home/partners-10.png'
import partners11 from '~/images/home/partners-11.png'
import partners12 from '~/images/home/partners-12.png'
import partners13 from '~/images/home/partners-13.png'
import partners14 from '~/images/home/partners-14.png'
import partners15 from '~/images/home/partners-15.png'
import partners16 from '~/images/home/partners-16.png'
import partners17 from '~/images/home/partners-17.png'
import partners18 from '~/images/home/partners-18.png'
import partners19 from '~/images/home/partners-19.png'
import partners20 from '~/images/home/partners-20.png'
import partners21 from '~/images/home/partners-21.png'
import partners22 from '~/images/home/partners-22.png'
import partners23 from '~/images/home/partners-23.png'
import partners24 from '~/images/home/partners-24.png'
import partners25 from '~/images/home/partners-25.png'
import partners26 from '~/images/home/partners-26.png'
import partners27 from '~/images/home/partners-27.png'
import _ from 'lodash'
import useWindowSize from '@/hooks/windowSize/Index'

const partnersPage: React.FC<any> = () => {
    const { t } = useTranslation()

    const [chunkCount, setChunkCount] = useState<number>(12)
    const { width, height } = useWindowSize()

    useEffect(() => {
        if (width < 768) {
            setChunkCount(4)
        } else {
            setChunkCount(12)
        }
    }, [width])

    const partners = [
        partners1,
        partners2,
        partners3,
        partners4,
        partners5,
        partners6,
        partners7,
        partners8,
        partners9,
        partners10,
        partners11,
        partners12,
        partners13,
        partners14,
        partners15,
        partners16,
        partners17,
        partners18,
        partners19,
        partners20,
        partners21,
        partners22,
        partners23,
        partners24,
        partners25,
        partners26,
        partners27,
    ]

    const PartnerList: any = () => {
        // 将新闻拆成二维数组，长度为12
        const list: any[] = _.chunk(partners, chunkCount)
        return (
            <Carousel className={`${styles.partnerList} wow fadeInUp`} autoplay={true} dots={false}>
                {list.map((item, index) => (
                    <div key={index}>
                        {item.map((i: any) => (
                            <img key={i} className={styles.partnerItem} src={i} />
                        ))}
                    </div>
                ))}
            </Carousel>
        )
    }
    return (
        <div className={styles.partner}>
            <div className={styles.height100}></div>
            <div className={`${styles.partnerTitle} wow fadeInUp`}>{t('合作伙伴')}</div>
            <PartnerList />
        </div>
    )
}

export default partnersPage
