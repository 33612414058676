import React, { useEffect, useState } from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'antd'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import newsList from '~/news/news.json'
import useWindowSize from '@/hooks/windowSize/Index'

const NewsPage: React.FC<any> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [chunkCount, setChunkCount] = useState<number>(3)
    const { width, height } = useWindowSize()

    useEffect(() => {
        if (width < 768) {
            setChunkCount(1)
        } else {
            setChunkCount(4)
        }
    }, [width])

    const news = newsList.research

    const handleClick = () => {
        navigate('/news')
    }

    const onNewsClick = (url) => {
        window.open(url)
    }

    const NewsList: any = () => {
        // 将新闻拆成二维数组，长度为4
        const list: any[] = _.chunk(news, chunkCount)
        return (
            <Carousel className={`${styles.newsList} wow fadeInRight`} dots={false} autoplay={true}>
                {list.map((item, index) => (
                    <div key={index} style={{ display: 'flex' }}>
                        {item.map((i: any, key) => (
                            <div key={key} className={`${styles.newsItem}`} onClick={() => onNewsClick(i?.url)}>
                                <img className={styles.newsImg} src={i.imageUrl} />
                                <div className={styles.newsContent}>
                                    <div className={styles.newsItemTitle}>{i.title}</div>
                                    <div className={styles.author}>
                                        <div className={styles.tagOriginal}>
                                            <span>{i.company ? '转载' : '原创'}</span>
                                        </div>
                                        {i.author && <span className={styles.authorName}>{i.author}</span>}
                                        <span className={styles.authorName}>{i.company || '英飞智药'}</span>
                                    </div>
                                    <div className={styles.content}>{i.desc}</div>
                                    <div className={styles.newsDate}>{i.date}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </Carousel>
        )
    }
    return (
        <div className={styles.news}>
            <div className={styles.height100}></div>
            <div className='wow fadeInUp'>
                <div className={`${styles.newsTitle}`}>{t('新闻中心')}</div>
            </div>
            <NewsList />
            <div className='wow fadeInUp'>
                <div className={`${styles.newsMore} ${styles.scaleAnim}`} onClick={handleClick}>
                    <span>{t('More')}</span>
                </div>
            </div>
        </div>
    )
}

export default NewsPage
