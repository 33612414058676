import React from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import pipelineImage from '~/images/home/pipeline-image.png'

const PipelinePage: React.FC<any> = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/pipeline')
    }

    return (
        <div className={styles.pipeline}>
            <div className={styles.height100}></div>
            <div className={styles.pipelineInfo}>
                <div className={`${styles.pipelineInfoLeft} wow fadeInUp`}>
                    <div className={styles.pipelineTitle}>{t('研发管线')}</div>
                    <div className={styles.pipelineDesc}>
                        {t(
                            '英飞智药以未满足的临床需求为导向，结合团队近25年AIDD/CADD技术积累，通过硬核的AI药物研发技术平台，致力于新靶点、新机制、新结构的原始创新药物设计和发现。现已开展自研+合作新药管线10余条，覆盖抗感染。抗肿瘤等多个疾病领域。'
                        )}
                    </div>
                    <div className={`${styles.pipelineMore} ${styles.scaleAnim}`} onClick={handleClick}>
                        <span>{t('More')}</span>
                    </div>
                </div>
                <div className={`${styles.pipelineImage} wow fadeInRight`} data-wow-delay="1s">
                    <img src={pipelineImage} />
                </div>
            </div>
        </div>
    )
}

export default PipelinePage
