import React, { useEffect, useRef, useState } from 'react'
import styles from './pharmamind.module.less'
import { useTranslation } from 'react-i18next'
import pharmaMind from '~/images/home/pharma-mind.png'
import mac from '~/images/home/mac-white.png'
import win from '~/images/home/win-white.png'
import molExplorer3d from '~/images/product/mol-explorer-3d.png'
import DeepMolBinder from '~/images/product/deep-mol-binder.png'
import DeepMolBuilder from '~/images/product/deep-mol-builder.png'
import DeepMolMiner from '~/images/product/deep-mol-miner.png'
import DeepTargetMiner from '~/images/product/deep-target-miner.png'
import DrugIDP from '~/images/product/drug-idp.png'
import MolDataMiner from '~/images/product/mol-data-miner.png'
import MolDOCKingVS from '~/images/product/mol-doc-king-vs.png'
import MolEvaluator from '~/images/product/mol-evaluator.png'
import MolInteractive from '~/images/product/mol-interactive.png'
import MolMultiTarget from '~/images/product/mol-multi-target.png'
import MolOptimizer3D from '~/images/product/mol-optimizer-3d.png'
import ProDesign from '~/images/product/pro-design.png'
import MolSearcher from '~/images/product/mol-searcher.png'
import MolSynthesize from '~/images/product/mol-synthesize.png'
import upIcon from '~/images/product/up.png'
import upDisableIcon from '~/images/product/up-disable.png'
import upHoverIcon from '~/images/product/up-hover.png'
import downIcon from '~/images/product/down.png'
import downDisableIcon from '~/images/product/down-disable.png'
import downHoverIcon from '~/images/product/down-hover.png'
import _ from 'lodash'
import { goToTop } from '@/utils'
import useWindowSize from '@/hooks/windowSize/Index'

const ProductPage: React.FC<any> = () => {
    const { t, i18n } = useTranslation()
    const [activeIndex, setActiveIndex] = useState<number>(3)
    const [upDisabled, setUpDisabled] = useState<boolean>(false)
    const [downDisabled, setDownDisabled] = useState<boolean>(false)
    const [upHover, setUpHover] = useState<boolean>(false)
    const [downHover, setDownHover] = useState<boolean>(false)
    const scrollRef = useRef(null)
    const { width, height } = useWindowSize()
    // 每一个滑块的高度
    const [itemHeight, setItemHeight] = useState<number>(0.52)
    // 滑块偏移量
    const offset = 3
    const products: any[] = [
        {
            name: t('MolMiner'),
            fullName: t('分子图像挖掘'),
            info: (
                <>
                    <div>{t('全面利用人工智能技术自动对分子图像进行快速、准确的结构化提取：')}</div>
                    <br></br>
                    <li>{t('支持批量PDF和图像输入，支持批量保存和标准格式下载')}</li>
                    <br></br>
                    <li>{t('快速构建完整的靶点相关的化合物专利空间')}</li>
                    <br></br>
                    <li>{t('支持特色私有数据的迭代与优化')}</li>
                    <br></br>
                    <li>{t('目前正在完善多模态的处理能力')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('MolBuilder'),
            fullName: t('三维分子设计'),
            info: (
                <>
                    <div>{t('利用生成式人工智能技术对靶点结构进行原位三维空间分子生成与优化：')}</div>
                    <br></br>
                    <li>{t('训练百万类药分子的三维结构与特性')}</li>
                    <br></br>
                    <li>{t('兼顾成药性与多样性')}</li>
                    <br></br>
                    <li>{t('强化学习生成高打分的三维化合物')}</li>
                    <br></br>
                    <li>{t('可探索整个靶点口袋空间')}</li>
                    <br></br>
                    <li>{t('适合FIC和BIC的分子设计引擎')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('PharmGPT™'),
            fullName: t('药效团驱动的分子设计'),
            info: (
                <>
                    <div>
                        {t(
                            '药效团是药物设计中被广泛应用的经验性知识，将该知识融入分子生成模型，提高分子生成的合理性和有效性：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('利用AI生成式GPT学习亿级分子的亿级模型')}</li>
                    <br></br>
                    <li>{t('与专家经验对齐的药效团Prompt微调')}</li>
                    <br></br>
                    <li>{t('适用于生成药效团类似而结构不类似的分子')}</li>
                    <br></br>
                    <li>{t('可支持配体药效团和受体药效团的分子设计')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('MolSynGen'),
            fullName: t('合成导向的分子设计'),
            info: (
                <>
                    <div>
                        {t(
                            '分子的合成可及性是药物设计中的重要限速因素，引入原料库和正向反应规则的二维分子设计，可提高分子的可合成性：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('融入药效团限制的易合成分子设计引擎')}</li>
                    <br></br>
                    <li>{t('根据原料库和反映规则生成易合成分子虚拟库')}</li>
                    <br></br>
                    <li>{t('利用生成式人工智能技术学习易合成的分子与路径')}</li>
                    <br></br>
                    <li>{t('可极大提高药物设计的验证与迭代速度')}</li>
                </>
            ),
            active: 1,
        },
        {
            name: t('MolFragGen'),
            fullName: t('基于片段的分子设计'),
            info: (
                <>
                    <div>
                        {t(
                            '片段替换与生长是药物设计中的常用策略，可在母核保持不变的情况下，针对可设计空间进行片段枚举：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('精选收集1500万分子片段库')}</li>
                    <br></br>
                    <li>{t('整合药效团叠合与亲合力打分进行大规模筛选')}</li>
                    <br></br>
                    <li>{t('三维构象原位生成与采样推荐优质片段')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('DL-DockVS'),
            fullName: t('超高通量虚拟筛选'),
            info: (
                <>
                    <div>{t('人工智能技术模拟对接筛选过程，利用分阶段模型快速筛选大规模分子数据库：')}</div>
                    <br></br>
                    <li>{t('学习分子对接过程中构象与打分')}</li>
                    <br></br>
                    <li>{t('实现对分子的一致性构象与打分的快速评价')}</li>
                    <br></br>
                    <li>{t('在保持召回率的情况下，实现了200倍虚筛速度的提升')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('MolSearcher '),
            fullName: t('分子多维度相似性搜索'),
            info: (
                <>
                    <div>
                        {t(
                            '相似性搜索是拓展分子多样性的快捷方法，不同的分子表征和不同的相似度度量方式往往召回多种多样的分子：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('整合了近4亿的虚拟分子库')}</li>
                    <br></br>
                    <li>{t('集成了30+种分子指纹和AI预训练表征')}</li>
                    <br></br>
                    <li>{t('提供不同相似维度度量的分子排序')}</li>
                    <br></br>
                    <li>{t('筛选性能优于各类传统指纹的筛选性能')}</li>
                    <br></br>
                    <li>{t('用来扩展目标分子的更多化学结构空间')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('MolEVA'),
            fullName: t('分子成药性质预测'),
            info: (
                <>
                    <div>
                        {t(
                            '对分子的性质进行全方面分子的性质决定了分子的临床可开发价值。早期对分子性质进行准确、全面的估计能够规避临床失败的风险：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('整合了近10万个各类分子性质数据与校准')}</li>
                    <br></br>
                    <li>{t('结合无监督学习+FewShot技术开发自动性质建模系统')}</li>
                    <br></br>
                    <li>{t('实现47种成药性质预测功能，性能达到实用水平')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('MolDock/MolDockScore'),
            fullName: t('分子对接与打分'),
            info: (
                <>
                    <div>
                        {t('对分子的三维结合构象进行采样与打分，对于结合模式进行定量的评价，发现潜在结合的分子：')}
                    </div>
                    <br></br>
                    <li>{t('实现对接采样与打分的GPU计算，速度提升500倍')}</li>
                    <br></br>
                    <li>{t('利用数据驱动+物理规律对矫正的高精度复合物数据进行自动微分调优')}</li>
                    <br></br>
                    <li>{t('打分与筛选效果性能优于先前公开工具')}</li>
                    <br></br>
                    <li>{t('在多个项目数据上体现较好的相关性，支持指导分子筛选和优化')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('InterScore'),
            fullName: t('蛋白与小分子的相互作用重打分'),
            info: (
                <>
                    <div>{t('分子在完成对接打分后，利用灵活的经验性评价方法，可提高挑选分子的成功率：')}</div>
                    <br></br>
                    <li>{t('实现基于药效团的叠合打分PharmScore')}</li>
                    <br></br>
                    <li>{t('实现基于形状和静电势的打分ShaEspScore')}</li>
                    <br></br>
                    <li>{t('实现蛋白与配体相互作用对的打分PLIPscore')}</li>
                    <br></br>
                    <li>{t('可根据项目自由配置满足不同的场景的需求')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('DynamicEval'),
            fullName: t('动力学能量评价'),
            info: (
                <>
                    <div>
                        {t(
                            '动力学评价能够用来判断分子结合状态的稳定性，利用动力学模拟和量化计算实现分子高精度的计算评价与推荐：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('实现本地和云平台上部署满足自动化大规模运行需求')}</li>
                    <br></br>
                    <li>{t('满足MMGBSA/ABFEP/FEP/Quantum的能量评价需求')}</li>
                    <br></br>
                    <li>{t('为更精细的分子挑选和优化提供关键的决策支持')}</li>
                    <br></br>
                    <li>{t('在多个项目上得到指导性应用')}</li>
                </>
            ),
            active: 0,
        },
        {
            name: t('RetroSynRoute'),
            fullName: t('分子逆合成分析'),
            info: (
                <>
                    <div>
                        {t(
                            '分子的合成是药物设计验证的关键步骤，逆合成分析有助于对目标分子进行拆解，通过多步反向推理找到相关的原料，从而推荐基于原料库的合成路径：'
                        )}
                    </div>
                    <br></br>
                    <li>{t('实现生成式AI学习技术对单步反应进行逆向预测')}</li>
                    <br></br>
                    <li>{t('对目标分子进行逆向拆解，推荐多条可行的反应路线')}</li>
                    <br></br>
                    <li>{t('根据原料的可及性和反应的可行性对路线进行排序打分')}</li>
                </>
            ),
            active: 0,
        },
    ]
    const [productList, setProductList] = useState(products)
    useEffect(() => {
        if (width < 576) {
            setItemHeight(1.1)
        } else {
            setItemHeight(0.52)
        }
    }, [width])

    useEffect(() => {
        setProductList(products)
    }, [i18n.language])

    useEffect(() => {
        goToTop()
    }, [])

    const handleUpClick = () => {
        const index = activeIndex - 1
        handleClick(index)
    }
    const handleDownClick = () => {
        const index = activeIndex + 1
        handleClick(index)
    }
    const handleUpEnter = () => {
        setUpHover(true)
    }
    const handleUpLeave = () => {
        setUpHover(false)
    }
    const handleDownEnter = () => {
        setDownHover(true)
    }
    const handleDownLeave = () => {
        setDownHover(false)
    }

    useEffect(() => {
        if (activeIndex <= 0) {
            setUpDisabled(true)
        } else if (activeIndex >= productList.length - 1) {
            setDownDisabled(true)
        } else {
            setUpDisabled(false)
            setDownDisabled(false)
        }
    }, [activeIndex])

    const handleClick = (index) => {
        if (index < 0) {
            return
        } else if (index > productList.length - 1) {
            return
        } else {
            setProductList((product) => {
                product.forEach((item) => {
                    item.active = 0
                })
                product[index].active = 1
                return product
            })
            scrollRef.current.style.transform = `translateY(${-itemHeight * (index - offset)}rem)`
            scrollRef.current.style.transition = 'transform 1s'
            setActiveIndex(index)
        }
    }

    const downloadMac = () => {
        const url = import.meta.env.VITE_DOWNLOAD_MAC_URL
        download(url)
    }
    const downloadWin = () => {
        const url = import.meta.env.VITE_DOWNLOAD_WIN_URL
        download(url)
    }
    const download = (url: string) => {
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.click()
    }

    return (
        <>
            <div id='download' className={styles.pharmamindBg}>
                <div className={styles.height160}></div>
                <div className={styles.pharmamind}>
                    <div className={`${styles.pharmamindLeft}`}>
                        <div className={styles.pharmamindTitle}>{t('PharmaMind®')}</div>
                        <div className={styles.pharmamindDesc}>
                            <div>{t('一站式分子设计与评估计算平台——PharmaMind®')}</div>
                            <ul>
                                <li>{t('针对不同来源的数据资料进行标准化的清洗与对齐流程')}</li>
                                <li>
                                    {t(
                                        '针对高质量数据，充分利用人工智能、计算机辅助药物设计、生物与药物信息学等前沿的技术，开发高质量计算模型'
                                    )}
                                </li>
                                <li>{t('灵活运用与迭代高质量模型，针对靶点结构或已知配体进行的高精度分子设计')}</li>
                                <li>
                                    {t('已对外开放模块：')}
                                    <ul>
                                        <li>{t('MolMiner（分子图像识别）')}</li>
                                        <li>{t('MolBuilder（基于结构的三维分子生成）')}</li>
                                        <li>{t('PharmGPT（基于药效团的二维分子生成）')}</li>
                                        <li>{t('MolEVA（分子基础性质预测）')}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`${styles.pharmamindRight}`}>
                        <img src={pharmaMind} />
                    </div>
                </div>
                <div className={styles.pharmamindDownload}>
                    <div className={`${styles.mac} animated `} onClick={downloadMac}>
                        <img src={mac} />
                        <span>{t('Mac版本下载')}</span>
                    </div>
                    <div className={styles.line}></div>
                    <div className={`${styles.windows} animated `} onClick={downloadWin}>
                        <img src={win} />
                        <span>{t('Windows版本下载')}</span>
                    </div>
                </div>
            </div>
            <div className={styles.productBg}>
                <div
                    className={styles.up}
                    onClick={() => {
                        handleUpClick()
                    }}
                    onMouseEnter={() => {
                        width < 768 ? () => {} : handleUpEnter()
                    }}
                    onMouseLeave={() => {
                        width < 768 ? () => {} : handleUpLeave()
                    }}
                    onTouchStart={() => {
                        handleUpEnter()
                    }}
                    onTouchEnd={() => {
                        handleUpLeave()
                    }}
                >
                    <img src={upDisabled ? upDisableIcon : upHover ? upHoverIcon : upIcon} />
                </div>
                <div className={styles.scrollWrapper}>
                    <div className={styles.scrollList} ref={scrollRef}>
                        {productList.map((item, index) => (
                            <div
                                key={item.name}
                                className={`${styles.scrollItem} ${item.active ? styles.scrollItemActive : ''}`}
                                onClick={() => handleClick(index)}
                            >
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.productContext}>
                    <div className={`${styles.productTitle}`}>{t('产品介绍')}</div>
                    <div className={`${styles.productDetail}`}>
                        <div className={styles.productDetailLeft}>
                            <div className={styles.productName}>{productList[activeIndex].name}</div>
                            <div className={styles.productFullName}>{productList[activeIndex]?.fullName}</div>
                            <div className={styles.productInfo}>{productList[activeIndex].info}</div>
                        </div>
                        <div className={styles.productDetailRight}>
                            {productList[activeIndex]?.image && <img src={productList[activeIndex].image} />}
                        </div>
                    </div>
                </div>
                <div
                    className={styles.down}
                    onClick={() => {
                        handleDownClick()
                    }}
                    onMouseEnter={() => {
                        handleDownEnter()
                    }}
                    onMouseLeave={() => {
                        handleDownLeave()
                    }}
                    onTouchStart={() => {
                        handleDownEnter()
                    }}
                    onTouchEnd={() => {
                        handleDownLeave()
                    }}
                >
                    <img src={downDisabled ? downDisableIcon : downHover ? downHoverIcon : downIcon} />
                </div>
            </div>
        </>
    )
}

export default ProductPage
