import { Route, Routes, Navigate } from 'react-router-dom'

import HomePage from '@/pages/home/Index'
import CompanyPage from '@/pages/company/Index'
import TechnicalPage from '@/pages/technical/Index'
import ContactUsPage from '@/pages/contactUs/Index'
import PipelinePage from '@/pages/pipeline/Index'
import NewsPage from '@/pages/news/Index'
import PharmaMindPage from '@/pages/pharmaMind/Index'
import TopTargetsPage from '@/pages/topTargets/Index'

const BasicRouter = () => (
    <Routes>
        <Route path='/' element={<Navigate to={'/home'} />} />
        <Route path='/home' element={<HomePage />} />
        <Route path='/company' element={<CompanyPage />} />
        <Route path='/technical' element={<TechnicalPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        <Route path='/pipeline' element={<PipelinePage />} />
        <Route path='/news' element={<NewsPage />} />
        <Route path='/pharma-mind' element={<PharmaMindPage />} />
        <Route path='/top-targets' element={<TopTargetsPage />} />
    </Routes>
)

export default BasicRouter
