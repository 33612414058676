import React, { useEffect } from 'react'
import styles from './news.module.less'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { CalendarOutlined } from '@ant-design/icons'
import newsList from '~/news/news.json'
import { goToTop } from '@/utils'

const NewsPage: React.FC<any> = () => {
    useEffect(() => {
        goToTop()
    }, [])

    const { t } = useTranslation()

    const companyNews = _.tail(newsList.company)

    const getDateAndDay = (date: string) => {
        const dateMoment = moment(date)
        const shortDate = dateMoment.format('YYYY-MM')
        const day = dateMoment.format('DD')
        return { date: shortDate, day }
    }

    const onNewsClick = (url) => {
        window.open(url)
    }

    return (
        newsList && (
            <>
                <div className={styles.news}>
                    <div className={styles.height220}></div>
                    <div className={styles.newsTitle}>{t('News')}</div>
                </div>
                <div className={styles.companyNews}>
                    <div className={styles.height90}></div>
                    <div className={styles.companyNewsTitle}>{t('公司新闻')}</div>
                    <div className={styles.newsList}>
                        <div className={styles.newsLeft} onClick={() => onNewsClick(newsList?.company[0]?.url)}>
                            <img src={newsList?.company[0]?.imageUrl} />
                            <div className={styles.newsItemDate}>
                                <div>{getDateAndDay(newsList?.company[0]?.date).day}</div>
                                <span>{getDateAndDay(newsList?.company[0]?.date).date}</span>
                            </div>
                            <div className={styles.newsItemTitle}>{newsList?.company[0]?.title}</div>
                            <div className={styles.newsItemDesc}>{newsList?.company[0]?.desc}</div>
                        </div>
                        <div className={styles.newsRight}>
                            {companyNews.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className={styles.newsRightItem}
                                    onClick={() => onNewsClick(item?.url)}
                                >
                                    <div className={styles.newsRightItemDate}>
                                        <div>{getDateAndDay(item?.date).day}</div>
                                        <span>{getDateAndDay(item?.date).date}</span>
                                    </div>
                                    <div className={styles.newsRightItemContext}>
                                        <div className={styles.title}>{item.title}</div>
                                        <div className={styles.desc}>{item.desc}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.researchNews}>
                    <div className={styles.researchNewsTitle}>{t('行业/科研进展')}</div>
                    <div className={styles.researchNewsList}>
                        {(newsList?.research || []).map((item: any, index: number) => (
                            <div key={index} className={styles.newsItem}>
                                <div className={styles.left}>
                                    <img src={item.imageUrl} />
                                </div>
                                <div className={styles.right} onClick={() => onNewsClick(item?.url)}>
                                    <div className={styles.title}>{item.title}</div>
                                    <div className={styles.date}>
                                        <CalendarOutlined className={styles.dateIcon} />
                                        <span className={styles.dateText}>{item.date}</span>
                                    </div>
                                    <div className={styles.desc}>{item.desc}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    )
}

export default NewsPage
