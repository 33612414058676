import React from 'react'
import ReactDOM from 'react-dom/client'
import 'amfe-flexible'
import 'antd/dist/antd.css'
import '@/styles/index.css'
import '@/i18n'
import 'animate.css'
import App from './App'

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
