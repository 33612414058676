import React, { useEffect } from 'react';
import Router from './routers/Index';
import Frame from '@/components/frame/Index';
import { HashRouter } from 'react-router-dom';
import WOW from 'wow.js'

const App: React.FC<any> = () => {

    useEffect(()=>{
        const wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true,
        })
        wow.init()
    }, [])
    
    return (
        <HashRouter>
            <Frame>
                <Router />
            </Frame>
        </HashRouter>
    );
};

export default App;
