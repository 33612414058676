import React, { useEffect, useRef, useState } from 'react'
import styles from './toptargets.module.less'
import { useTranslation } from 'react-i18next'
import topTargets from '~/images/home/top-targets.png'
import mac from '~/images/home/mac-white.png'
import win from '~/images/home/win-white.png'
import BioKG from '~/images/product/bio-kg.png'
import upIcon from '~/images/product/up.png'
import upDisableIcon from '~/images/product/up-disable.png'
import upHoverIcon from '~/images/product/up-hover.png'
import downIcon from '~/images/product/down.png'
import downDisableIcon from '~/images/product/down-disable.png'
import downHoverIcon from '~/images/product/down-hover.png'
import _ from 'lodash'
import { goToTop } from '@/utils'
import useWindowSize from '@/hooks/windowSize/Index'

const TopTargetsPage: React.FC<any> = () => {
    const { t, i18n } = useTranslation()
    const [activeIndex, setActiveIndex] = useState<number>(3)
    const [upDisabled, setUpDisabled] = useState<boolean>(false)
    const [downDisabled, setDownDisabled] = useState<boolean>(false)
    const [upHover, setUpHover] = useState<boolean>(false)
    const [downHover, setDownHover] = useState<boolean>(false)
    const scrollRef = useRef(null)
    const { width, height } = useWindowSize()
    // 每一个滑块的高度
    const [itemHeight, setItemHeight] = useState<number>(0.52)
    // 滑块偏移量
    const offset = 3
    const products = [
        {
            name: t('BioTargetKG'),
            fullName: t('靶点驱动知识图谱数据库'),
            info: (
                <>
                    <div>
                        {t(
                            '靶点驱动知识图谱主要是以疾病-靶点-化合物为中心的知识图谱，为针对疾病挖掘新靶点和新机制提供数据支持：'
                        )}
                    </div>
                    <br />
                    <li>{t('积累了25年的私有数据资料')}</li>
                    <br />
                    <li>{t('整合了30M生物医学文献资料和50个公开数据库')}</li>
                    <br />
                    <li>{t('对文本和图像类非结构化数据的结构化实体与关系提取')}</li>
                    <li>{t('实现数据自动清洗、更新，以及专家矫正')}</li>
                    <br />
                    <li>{t('为大语言模型的开发提供高价值的数据资源')}</li>
                </>
            ),
            active: 0,
            image: BioKG,
        },
        {
            name: t('PocketMiner'),
            fullName: t('基于蛋白结构的信息挖掘引擎'),
            info: (
                <>
                    <div>
                        {t(
                            '蛋白的三维结构决定了其发挥生物功能。利用AI空间几何算法对靶点结构的进行口袋探测与分析，从而对靶点的可开发性有定量与定性的认识：'
                        )}
                    </div>
                    <br />
                    <li>{t('实现对靶点结构的口袋的高精度探测与可药性分析')}</li>
                    <br />
                    <li>{t('实现对别构口袋、共价位点预测识别')}</li>
                    <br />
                    <li>{t('实现对口袋的耐药突变与能量计算分析')}</li>
                    <br />
                    <li>{t('实现对口袋的动力学性质的计算评价')}</li>
                    <br />
                    <li>{t('实现对口袋网络相似度的计算评价')}</li>
                </>
            ),
            active: 1,
            image: BioKG,
        },
        {
            name: t('SeqMiner'),
            fullName: t('基于蛋白序列的信息挖掘引擎'),
            info: (
                <>
                    <div>
                        {t(
                            '蛋白的序列决定了蛋白的三维结构。挖掘大量的序列中未被发现的信息能够对生物机制有更全面的认识：'
                        )}
                    </div>
                    <br />
                    <li>{t('整合了AI预训练蛋白表征技术')}</li>
                    <br />
                    <li>{t('实现对蛋白与蛋白相互作用的预测评价')}</li>
                    <br />
                    <li>{t('实现对蛋白发生相分离的预测评价')}</li>
                    <br />
                    <li>{t('实现对蛋白可药性的预测评价')}</li>
                    <br />
                    <li>{t('通过对大规模序列数据的筛选获得有价值的蛋白序列')}</li>
                </>
            ),
            active: 0,
            image: BioKG,
        },
        {
            name: t('BioNetMiner'),
            fullName: t('基于生物疾病网络的信息挖掘引擎'),
            info: (
                <>
                    <div>
                        {t(
                            '生物疾病网络涵盖了大量多层次的相互作用信息，关键的相互作用导致了疾病的发生。基于生物疾病网络进行系统生物学计算分析，从而得到具有开发潜力的高价值靶点：'
                        )}
                    </div>
                    <br />
                    <li>{t('利用多模态图网络技术构建相互作用预测模型')}</li>
                    <br />
                    <li>{t('利用常微分方程和流平衡模拟分析网络节点的动态变化')}</li>
                    <br />
                    <li>{t('通过多维度的技术分析为专家提供指导性建议')}</li>
                </>
            ),
            active: 0,
            image: BioKG,
        },
    ]
    const [productList, setProductList] = useState(products)
    useEffect(() => {
        if (width < 576) {
            setItemHeight(1.1)
        } else {
            setItemHeight(0.52)
        }
    }, [width])

    useEffect(() => {
        setProductList(products)
    }, [i18n.language])

    useEffect(() => {
        goToTop()
    }, [])

    const handleUpClick = () => {
        const index = activeIndex - 1
        handleClick(index)
    }
    const handleDownClick = () => {
        const index = activeIndex + 1
        handleClick(index)
    }
    const handleUpEnter = () => {
        setUpHover(true)
    }
    const handleUpLeave = () => {
        setUpHover(false)
    }
    const handleDownEnter = () => {
        setDownHover(true)
    }
    const handleDownLeave = () => {
        setDownHover(false)
    }

    useEffect(() => {
        if (activeIndex <= 0) {
            setUpDisabled(true)
        } else if (activeIndex >= productList.length - 1) {
            setDownDisabled(true)
        } else {
            setUpDisabled(false)
            setDownDisabled(false)
        }
    }, [activeIndex])

    const handleClick = (index) => {
        if (index < 0) {
            return
        } else if (index > productList.length - 1) {
            return
        } else {
            setProductList((product) => {
                product.forEach((item) => {
                    item.active = 0
                })
                product[index].active = 1
                return product
            })
            // scrollRef.current.style.transform = `translateY(${-itemHeight * (index - offset)}rem)`
            // scrollRef.current.style.transition = 'transform 1s'
            setActiveIndex(index)
        }
    }

    const downloadMac = () => {
        const url = import.meta.env.VITE_DOWNLOAD_MAC_URL
        download(url)
    }
    const downloadWin = () => {
        const url = import.meta.env.VITE_DOWNLOAD_WIN_URL
        download(url)
    }
    const download = (url: string) => {
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.click()
    }

    return (
        <>
            <div id='download' className={styles.pharmamindBg}>
                <div className={styles.height160}></div>
                <div className={styles.pharmamind}>
                    <div className={`${styles.pharmamindLeft}`}>
                        <div className={styles.pharmamindTitle}>{t('TopTargets®')}</div>
                        <div className={styles.pharmamindDesc}>
                            <div>{t('靶点发现与评价计算平台——TopTargets®')}</div>
                            <ul>
                                <li>{t('由临床需求出发，从系统生物学角度关注疾病及其关联网络')}</li>
                                <li>
                                    {t(
                                        '探索生物分子间的相互作用，以全面地理解疾病发生机制，保证靶点发现的合理性与有效性'
                                    )}
                                </li>
                                <li>{t('以疾病-靶点-化合物为中心的生物疾病知识图谱')}</li>
                                <li>
                                    {t(
                                        '通过生物疾病网络、蛋白序列与结构、化合物等多层次人工智能信息挖掘技术来评价潜在的靶点'
                                    )}
                                </li>
                                <li>{t('结合市场需求形成靶点的可开发性评价报告')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className={`${styles.pharmamindRight}`}>
                        <img src={topTargets} />
                    </div>
                </div>
                <div className={styles.pharmamindDownload}>
                    <div className={`${styles.mac} animated `} onClick={downloadMac}>
                        <img src={mac} />
                        <span>{t('Mac版本下载')}</span>
                    </div>
                    <div className={styles.line}></div>
                    <div className={`${styles.windows} animated `} onClick={downloadWin}>
                        <img src={win} />
                        <span>{t('Windows版本下载')}</span>
                    </div>
                </div>
            </div>
            <div className={styles.productBg}>
                <div
                    className={styles.up}
                    onClick={() => {
                        handleUpClick()
                    }}
                    onMouseEnter={() => {
                        width < 768 ? () => {} : handleUpEnter()
                    }}
                    onMouseLeave={() => {
                        width < 768 ? () => {} : handleUpLeave()
                    }}
                    onTouchStart={() => {
                        handleUpEnter()
                    }}
                    onTouchEnd={() => {
                        handleUpLeave()
                    }}
                >
                    <img src={upDisabled ? upDisableIcon : upHover ? upHoverIcon : upIcon} />
                </div>
                <div className={styles.scrollWrapper}>
                    <div className={styles.scrollList} ref={scrollRef}>
                        {productList.map((item, index) => (
                            <div
                                key={item.name}
                                className={`${styles.scrollItem} ${item.active ? styles.scrollItemActive : ''}`}
                                onClick={() => handleClick(index)}
                            >
                                <span>{item.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.productContext}>
                    <div className={`${styles.productTitle}`}>{t('产品介绍')}</div>
                    <div className={`${styles.productDetail}`}>
                        <div className={styles.productDetailLeft}>
                            <div className={styles.productName}>{productList[activeIndex].name}</div>
                            <div className={styles.productFullName}>{productList[activeIndex]?.fullName}</div>
                            <div className={styles.productInfo}>{productList[activeIndex].info}</div>
                        </div>
                        <div className={styles.productDetailRight}>
                            <img src={productList[activeIndex].image} />
                        </div>
                    </div>
                </div>
                <div
                    className={styles.down}
                    onClick={() => {
                        handleDownClick()
                    }}
                    onMouseEnter={() => {
                        handleDownEnter()
                    }}
                    onMouseLeave={() => {
                        handleDownLeave()
                    }}
                    onTouchStart={() => {
                        handleDownEnter()
                    }}
                    onTouchEnd={() => {
                        handleDownLeave()
                    }}
                >
                    <img src={downDisabled ? downDisableIcon : downHover ? downHoverIcon : downIcon} />
                </div>
            </div>
        </>
    )
}

export default TopTargetsPage
