import React, { useEffect } from 'react'
import styles from './home.module.less'
import GeneralPage from './general'
import ProductPage from './product'
import SupportsPage from './supports'
import PipelinePage from './pipeline'
import NewsPage from './news'
import Partners from './partners'
import { goToTop } from '@/utils/index'

const Index: React.FC<any> = () => {
    useEffect(() => {
        goToTop()
    }, [])

    return (
        <div className={styles.page}>
            <GeneralPage />
            <ProductPage />
            <SupportsPage />
            <PipelinePage />
            <NewsPage />
            <Partners />
        </div>
    )
}

export default Index
