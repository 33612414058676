import React, { useState, useEffect } from 'react'
import styles from './contactUs.module.less'
import { useTranslation } from 'react-i18next'
import { Input, message } from 'antd'
import phoneIcon from '~/images/contactUs/phone.png'
import businessIcon from '~/images/contactUs/business.png'
import othersIcon from '~/images/contactUs/others.png'
import jobIcon1 from '~/images/contactUs/job-icon1.png'
import jobIcon2 from '~/images/contactUs/job-icon2.png'
import jobIcon3 from '~/images/contactUs/job-icon3.png'
import baseIcon from '~/images/contactUs/base-icon.png'
import _ from 'lodash'
import { goToTop } from '@/utils'
import { sendContact } from '@/api/index'
import copy from 'copy-to-clipboard'

const ContactUsPage: React.FC<any> = () => {
    const { t } = useTranslation()
    const [info, setInfo] = useState<any>({
        uuid: localStorage.getItem('uuid'),
        name: '',
        email: '',
        mobile: '',
        content: '',
    })

    useEffect(() => {
        goToTop()
    }, [])

    const Jobs = [
        {
            title: 'BD经理',
            background: 'linear-gradient(137deg, #ABF5EA 0%, #E4FEFA 100%)',
            base: t('北京/苏州'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 根据公司总体战略规划，寻找与国内外医药企业等的合作机会；</span>
                    <span>2. 制定商务拓展运营策略和谈判方案，与合作方进行商务洽谈；</span>
                    <span>3. 负责日常客户的信息沟通对接，已合作项目的跟踪合作，维护长期合作资源；</span>
                    <span>4. 根据决策结果，推进和把控项目合作进度；</span>
                    <span>5. 参与组织协调目标项目的立项调研、可行性评价报告及立项评审等工作。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>1. 全日制本科及以上学历，药学相关专业背景优先；</span>
                    <span>2. 三年以上医药行业相关经验，有药企资源者优先；</span>
                    <span>3. 有跨部门协调与合作的沟通能力，优秀的商务谈判能力。</span>
                </>
            ),
            icon: jobIcon1,
        },
        {
            title: t('运营经理'),
            background: 'linear-gradient(137deg, #FFEEC8 0%, #FEF7E4 100%)',
            base: t('北京'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 负责产品0-1的推广，通过渠道投放及推广，控制成本并达成拉新目标；</span>
                    <span>
                        2.
                        用户运营，建立用户分层策略、激励措施，制定拉新、活跃、留存、转化等一系列完善的运营规划并执行；
                    </span>
                    <span>3. 优秀的产品运营能力，能胜任从运营规划到落地执行；</span>
                    <span> 4. 工作主动积极，具有责任心和自驱力，医药相关背景/经验优先。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>1. 本科以上学历，3年以上运营工作经验；</span>
                    <span>2. 具备较强的资源拓展判力，有一定的活动策划和组织能力；</span>
                    <span>3. 内容社区搭建，负责KOL资源拓展及维护，建立成长体系；</span>
                    <span>4. 针对运营工作进行数据跟踪分析，监测市场发展动态，发现用户需求，配合产品经理改进产品；</span>
                    <span>5. 市场敏感度高，快速反应市场环境变化并且做出运营策略调整。</span>
                </>
            ),
            icon: jobIcon2,
        },
        {
            title: t('药物化学研究员'),
            background: 'linear-gradient(137deg, #E8EAF3 0%, #F4F5F9 100%)',
            base: t('北京'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 调研药物靶点信息；</span>
                    <span>2. 负责新药研发品种从化学合成到临床前测试的研究分析和过程管理；</span>
                    <span>3. 调研并分析新药品种的市场动态、产品形态和竞争态势；</span>
                    <span>4. 协助药物产品开发、产品培训、产品手册制作等相关工作；</span>
                    <span>5. 与计算机辅助药物设计和人工智能药物设计部门合作进行新药开发。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>
                        1.
                        药学化学等相关专业硕士以上学历（博士优先），有药物化学合成、新药研发信息调研、专利调研和申请相关工作经验优先；
                    </span>
                    <span>
                        2. 熟悉国内外药品研发趋势，精通药物及靶点信息查询常用网站及商业数据库，对相关信息检索工具熟悉；
                    </span>
                    <span>3. 具有团队合作精神，以产品或项目为核心，共同配合推进工作进程；</span>
                    <span>4. 具有强烈的进取心，深入挖掘相关信息；</span>
                    <span>
                        5.
                        熟悉药物研发流程，具有较强的文献阅读和理解能力，有较强的中、英文写作能力及PPT制作与汇报展示能力。
                    </span>
                </>
            ),
            icon: jobIcon3,
        },
        {
            title: t('药物设计研究员'),
            background: '#FFFFFF',
            base: t('北京'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 与药物化学团队合作，运用计算化学的方法和相关软件进行小分子类药物设计开发的工作；</span>
                    <span>2. 与AI研发团队一起，编写代码完成机器学习和计算化学相关工作。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>1. 计算机辅助药物设计方向，了解的理论研究和算法；</span>
                    <span>2. 硕士以上学历、计算化学、计算生物学等专业；</span>
                    <span>3. 具有良好的编程能力，至少掌握一门编程语言(Python, C/C++，Fortran等）</span>
                    <span>
                        4. 具有分子对接、分子动力学模拟、蛋白质建模、自由能计算或化学信息学的理论知识和相关项目经验；
                    </span>
                    <span>5. 具有完整算法开发经验者优先考虑。</span>
                </>
            ),
            icon: jobIcon3,
        },
        {
            title: t('AI药物设计算法研究员'),
            background: '#FFFFFF',
            base: t('北京'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 理解药物设计痛点问题；</span>
                    <span>2. 开展药物设计与机器学习的创新研究，推动优化药物设计成功率与药物研发速度；</span>
                    <span>3. 研究人工智能领域的算法前沿技术，例如自然语言处理，图神经网络等方向。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>
                        1.
                        模式识别与智能系统、信号与信息处理、计算机应用技术、计算机软件、自动化、数学、统计学或相关专业硕士及以上；
                    </span>
                    <span>2. 有扎实的自然语言处理和机器学习基础，有独立负责并完成实际项目经验者优先；</span>
                    <span>
                        3.
                        熟练使用C/C++、Python，熟练掌握TensorFlow、Caffe、PyTorch、MXNet等至少一种深度学习框架和常用网络模型；
                    </span>
                    <span>4. 具有较强算法实现能力，在相关领域顶级会议或期刊发表过论文优先；</span>
                    <span>5. 对研究工作充满热情，能够跟踪学术前沿并实现，保持算法在工业界和学术界的先进。</span>
                </>
            ),
            icon: jobIcon3,
        },
        {
            title: t('React前端开发工程师'),
            background: '#FFFFFF',
            base: t('北京'),
            desc: (
                <>
                    <span className={styles.fontSize14}>{t(`职责描述：`)}</span>
                    <span>1. 负责公司产品、官网和运营系统的前端设计、开发、优化与维护；</span>
                    <span>2. 前端与后台服务数据交互的开发；</span>
                    <span>3. 持续关注新技术，引入并优化前端的质量、性能与用户体验；</span>
                    <span>4. 善于沟通，可以独立解决问题，保证开发的质量与进度。</span>
                    <span className={styles.fontSize14}>{t(`任职要求：`)}</span>
                    <span>1. 本科以上学历，三年以上前端开发经验；</span>
                    <span>2. 良好的代码编写风格、周全的代码错误预判能力、完备的软件研发与发布流程；</span>
                    <span>3. 精通前端开发技术，包括HTML5、CSS3、JavaScript、TypeScript等， 熟练使用ES6优先；</span>
                    <span>4. 掌握跨平台桌面App研发技术，包含Electron、NodeJS等，有相关的项目经验优先；</span>
                    <span>5. 掌握移动端App研发技术，包含Android应用、Wechat小程序等；</span>
                    <span>6. 至少掌握一个主流的JS框架（React、Vue），并对框架内部实现原理有比较深入的理解。</span>
                </>
            ),
            icon: jobIcon3,
        },
    ]

    const onTextClick = (text) => {
        copy(text)
        message.success(t('内容已复制'))
    }

    const JobList = _.chunk(Jobs, 3)

    const handleSubmit = () => {
        if (!info.mobile || !info.name || !info.content) {
            message.warning(t('请完善您的信息'))
            return
        }
        message.success(t('发送成功'))
        sendContact({ ...info })
    }

    const handleSend = _.throttle(handleSubmit, 2000, { leading: true })

    const setName = (e) => {
        setInfo({ ...info, name: e.target.value })
    }
    const setMobile = (e) => {
        const { value } = e.target
        const reg = /^-?\d*(\.\d*)?$/
        if (reg.test(value) || value === '') {
            setInfo({ ...info, mobile: value })
        }
    }
    const setEmail = (e) => {
        setInfo({ ...info, email: e.target.value })
    }
    const setContent = (e) => {
        setInfo({ ...info, content: e.target.value })
    }

    return (
        <>
            <div className={styles.connection}>
                <div className={styles.height220}></div>
                <div className={styles.connectionTitle}>{t('同舟共济，劈浪向前')}</div>
            </div>
            <div className={styles.contactUs}>
                <div className={styles.height80}></div>
                <div className={styles.contactUsTitle}>{t('联系我们')}</div>
                <div className={styles.contactInfo}>
                    <div className={styles.contactItem}>
                        <div className={styles.contactIcon}>
                            <img src={phoneIcon} />
                        </div>
                        <div className={styles.contactText}>{t('联系方式')}</div>
                        <div
                            className={styles.contactValue}
                            onClick={() => {
                                onTextClick('+86 18518416618')
                            }}
                        >
                            +86 18518416618
                        </div>
                    </div>
                    <div className={styles.contactItem}>
                        <div className={styles.contactIcon}>
                            <img src={businessIcon} />
                        </div>
                        <div className={styles.contactText}>{t('商务合作')}</div>
                        <div
                            className={styles.contactValue}
                            onClick={() => {
                                onTextClick('bd@iipharma.cn')
                            }}
                        >
                            bd@iipharma.cn
                        </div>
                    </div>
                    <div className={styles.contactItem}>
                        <div className={styles.contactIcon}>
                            <img src={othersIcon} />
                        </div>
                        <div className={styles.contactText}>{t('其他业务')}</div>
                        <div
                            className={styles.contactValue}
                            onClick={() => {
                                onTextClick('service@iipharma.cn')
                            }}
                        >
                            service@iipharma.cn
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.userInfo}>
                        <Input
                            className={styles.input}
                            value={info.name}
                            onChange={setName}
                            placeholder={t('Your Name *')}
                        ></Input>
                        <Input
                            maxLength={11}
                            className={styles.input}
                            value={info.mobile}
                            onChange={setMobile}
                            placeholder={t('Phone Number *')}
                        ></Input>
                        <Input
                            className={styles.input}
                            value={info.email}
                            onChange={setEmail}
                            placeholder={t('Your Email')}
                        ></Input>
                    </div>
                    <div className={styles.context}>
                        <Input.TextArea
                            className={styles.inputText}
                            value={info.content}
                            onChange={setContent}
                            autoSize={{ minRows: 5, maxRows: 5 }}
                            placeholder={t('Your Message *')}
                        ></Input.TextArea>
                    </div>
                    <div className={`${styles.sendBtn} animated`} onClick={handleSend}>
                        <span>{t('Send Message')}</span>
                    </div>
                </div>
            </div>

            <div className={styles.joinUs}>
                <div className={styles.height80}></div>
                <div className={styles.joinUsTitle}>{t('Join us')}</div>
                {JobList.map((item, index) => (
                    <div key={index} className={styles.jobList}>
                        {item.map((i, key) => (
                            <div className={styles.jobItemWrapper} style={{ background: i.background }}>
                                <div key={key} className={styles.jobItem}>
                                    <div className={styles.jobItemTitle}>{i.title}</div>
                                    <div className={styles.jobItemBase}>
                                        <img className={styles.jobItemBaseIcon} src={baseIcon} />
                                        <div className={styles.jobItemBaseText}>{i.base}</div>
                                    </div>
                                    <div className={styles.jobItemDesc}>{i.desc}</div>
                                    <img className={styles.jobItemImg} src={i.icon} />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className={styles.joinUsFooter}>
                <div className={styles.height60}></div>
                <div className={styles.joinUsFooterTitle}>{t('深思明辨，百药可成')}</div>
                <div className={styles.joinUsFooterDesc}>
                    {t('如果您对我们工作岗位感兴趣或者有什么建议，欢迎发送邮件给我们')}
                </div>
                <div className={`${styles.hrEmail} animated`}>
                    <span>hr@iipharma.cn</span>
                </div>
            </div>
        </>
    )
}

export default ContactUsPage
