import React, { useEffect } from 'react'
import styles from './technical.module.less'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { goToTop } from '@/utils'
import targetIdentification from '~/images/technical/target-identification.png'
import hitIdentification from '~/images/technical/hit-identification.png'
import leadOptimization from '~/images/technical/lead-optimization.png'
import preclinicalCandidateCompounds from '~/images/technical/preclinical-candidate-compounds.png'

const TechnicalPage: React.FC<any> = () => {
    const { t } = useTranslation()

    useEffect(() => {
        goToTop()
    }, [])

    return (
        <>
            <div className={styles.technical}>
                <div className={styles.height220}></div>
                <div className={styles.technicalTitle}>{t('Technical introduction')}</div>
            </div>
            <div className={styles.flow}>
                <div className={styles.height78}></div>
                <div className={`${styles.flowTitle}`}>
                    {t('药物发现')}
                    <span style={{ color: '#41A99A' }}>{t('一体化服务')}</span>
                </div>
                <div className={`${styles.flowDesc}`}>
                    {t(
                        '智药大脑（PharmaMind）平台打造国际最硬核的AI药物设计系统伙伴，专注于发展应用于真实世界的药物设计技术，为新药研发提供高精度的AI技术服务和解决方案，可为早期药物研发提供一站式服务，包括且不限于：靶点发现和选择、苗头化合物和先导化合物发现、先导化合物优化、PCC和Pre-IND品种开发等。'
                    )}
                </div>
                <div className={styles.flowBg}>
                    <div className={`${styles.flowItem}`}>
                        <div>
                            <img id='targetIdentification' className={`${styles.tiImg}`} src={targetIdentification} />
                        </div>
                        <div className={`${styles.flowItemText} ${styles.tiText}`}>
                            <div className={styles.flowItemTitle}>{t('Target Identification/Selection')}</div>
                            <span>
                                {t(
                                    '基于英飞智药独特的AI创新靶点发现平台TopTargets，利用AI技术挑选出最有价值和最容易成药的靶点，进行FIC或BIC药物开发。'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className={`${styles.flowItem}`}>
                        <div>
                            <img id='hitIdentification' className={`${styles.hiImg}`} src={hitIdentification} />
                        </div>
                        <div className={`${styles.flowItemText} ${styles.hiText}`}>
                            <div className={`${styles.flowItemTitle}`}>{t('Hit Identification')}</div>
                            <span>
                                {t(`利用AIDD/CADD融合的(超高通量)虚拟筛选、从头药物分子设计、（基于结构）的药物分子生成、多靶点药物分子设计、针对IDP的药物分子设计等技术，高效设计和发现苗头/先导化合物。`)}
                            </span>
                        </div>
                    </div>
                    <div className={`${styles.flowItem}`}>
                        <div>
                            <img id='leadOptimization' className={`${styles.loImg}`} src={leadOptimization} />
                        </div>
                        <div className={`${styles.flowItemText} ${styles.loText}`}>
                            <div className={`${styles.flowItemTitle}`}>{t('Lead Optimization')}</div>
                            <span>
                                {t(
                                    `对于苗头分子/先导分子结构进行优化改造，提高生物活性，改善药代动力学性质，降低毒性，获得安全有效，具有后续开发价值的候选药物；`
                                )}
                                <br></br>
                                {t(
                                    `基于靶点结构进行优化，在提高结合能力的基础上，同时评估化合物的成药性和可合成性，缩短实际反馈所需的资源和时间，快速获得更有可能成药的先导/候选化合物分子。`
                                )}
                            </span>
                        </div>
                    </div>
                    <div className={`${styles.flowItem}`}>
                        <div>
                            <img id='preclinicalCandidateCompounds' className={`${styles.pccImg}`} src={preclinicalCandidateCompounds} />
                        </div>
                        <div className={`${styles.flowItemText} ${styles.pccText}`}>
                            <div className={`${styles.flowItemTitle}`}>
                                {t('Preclinical Candidate Compounds')}
                            </div>
                            <span>
                                {t(`结构决定性质，化合物的性质在前期如果能够早一步考虑，将在开发阶段事半功倍；`)}
                                <br></br>
                                {t(
                                    `使用AI模型，对于一个化合物的多维度性质进行评价，包括药理活性、药代动力学性质和各种毒性和安全性，可上市性潜力等进行预测和优化，极大提高药物研发的成功率。`
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.cooperation}>
                <div className={styles.height75}></div>
                <div className={`${styles.cooperationTitle}`}>{t('合作联系')}</div>
                <div className={`${styles.cooperationDesc}`}>{t('如果您想与我们合作，欢迎发送邮件给我们')}</div>
                <div className={`${styles.bdEmail} ${styles.scaleAnim}`}>
                    <a href='mailto:bd@iipharma.cn?subject=合作联系&cc=&&body=如果您想与我们合作，欢迎发送邮件给我们'>
                        <span>bd@iipharma.cn</span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default TechnicalPage
