import React, { useEffect, useState } from 'react'
import styles from './company.module.less'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { goToTop } from '@/utils/index'
import certificate1 from '~/images/company/certificate-1.png'
import certificate2 from '~/images/company/certificate-2.png'
import certificate3 from '~/images/company/certificate-3.png'
import certificate4 from '~/images/company/certificate-4.png'
import certificate5 from '~/images/company/certificate-5.png'
import JianfengPei from '~/images/company/JianfengPei.jpg'
import YoujunXu from '~/images/company/YoujunXu.jpg'
import WeilinZhang from '~/images/company/WeilinZhang.jpg'
import JinchuanXiao from '~/images/company/JinchuanXiao.jpg'
import TianlongHao from '~/images/company/TianlongHao.jpg'
import GuozhenWu from '~/images/company/GuozhenWu.jpg'
import ChaojunGong from '~/images/company/ChaojunGong.jpg'
import HuaYang from '~/images/company/HuaYang.jpg'
import mission from '~/images/company/mission.png'
import vision from '~/images/company/vision.png'
import { lngs } from '@/i18n'
import useWindowSize from '../../hooks/windowSize/Index'

const Company: React.FC<any> = () => {
    const { t, i18n } = useTranslation()
    const [current, setCurrent] = useState<number>(0)
    const { width } = useWindowSize()
    const [images, setImages] = useState<any[]>([
        {
            url: certificate1,
            style: {
                transition: 'transform 1s ease 0.1s',
                transform: 'translateZ(0rem)',
            },
            onClick: () => {},
            onMouseEnter: () => {},
            onMouseOut: () => {},
        },
        {
            url: certificate2,
            style: {
                transition: 'transform 1s ease 0.1s',
                transform: 'translateX(-2rem) translateZ(-1rem)',
            },
            onClick: () => {},
            onMouseEnter: () => {},
            onMouseOut: () => {},
        },
        {
            url: certificate3,
            style: {
                transition: 'transform 1s ease 0.1s',
                transform: 'translateX(-4rem) translateZ(-2.5rem)',
            },
            onClick: () => {},
            onMouseEnter: () => {},
            onMouseOut: () => {},
        },
        {
            url: certificate4,
            style: {
                transition: 'transform 1s ease 0.1s',
                transform: 'translateX(4rem) translateZ(-2.5rem)',
            },
            onClick: () => {},
            onMouseEnter: () => {},
            onMouseOut: () => {},
        },
        {
            url: certificate5,
            style: {
                transition: 'transform 1s ease 0.1s',
                transform: 'translateX(2rem) translateZ(-1rem)',
            },
            onClick: () => {},
            onMouseEnter: () => {},
            onMouseOut: () => {},
        },
    ])

    let timer: any
    const imgLength = images.length

    useEffect(() => {
        bindEvent()
        autoplay()
        goToTop()
        return () => {
            timer && clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        turn()
    }, [current])

    const turn = () => {
        const carouselLenght = Math.floor(imgLength / 2)
        let leftImg = 0
        let rightImg = 0
        const imagesCopy = _.cloneDeep(images)
        for (let i = 0; i < carouselLenght; i++) {
            leftImg = imgLength + current - i - 1
            rightImg = current + i + 1
            if (leftImg >= imgLength) {
                leftImg -= imgLength
            }
            if (rightImg >= imgLength) {
                rightImg -= imgLength
            }
            imagesCopy[leftImg].style.transform = `translateX(${2 * (i + 1)}rem) translateZ(${-1 - i * 1.5}rem)`
            imagesCopy[rightImg].style.transform = `translateX(${-2 * (i + 1)}rem) translateZ(${-1 - i * 1.5}rem)`
        }
        imagesCopy[current].style.transform = `translateZ(0rem)`
        setImages(imagesCopy)
    }
    const bindEvent = () => {
        let imagesCopy = images
        imagesCopy.forEach((item: any, index: number) => {
            item.onClick = () => {
                setCurrent(index)
                turn()
            }
            item.onMouseEnter = () => {
                timer && clearInterval(timer)
            }
            item.onMouseOut = () => {
                autoplay()
            }
        })
        setImages(imagesCopy)
    }

    const autoplay = () => {
        timer = setInterval(() => {
            setCurrent((current) => {
                if (current >= imgLength - 1) {
                    return 0
                } else {
                    return current + 1
                }
            })
        }, 3000)
    }

    const members = [
        // {
        //     name: t('裴剑锋'),
        //     job: t('首席科学家'),
        //     education: t('博士'),
        //     url: JianfengPei,
        //     color: '#19D798',
        // },
        {
            name: t('徐优俊'),
            job: t('AIDD负责人'),
            education: t('博士'),
            url: YoujunXu,
            color: '#19BBD7',
        },
        {
            name: t('张伟林'),
            job: t('CADD负责人 '),
            education: t('博士'),
            url: WeilinZhang,
            color: '#1926D7',
        },
        {
            name: t('肖晋川'),
            job: t('IT工程负责人'),
            education: t('硕士'),
            url: JinchuanXiao,
            color: '#A219D7',
        },
        {
            name: t('龚超骏'),
            job: t('苏州研发负责人'),
            education: t('博士'),
            url: ChaojunGong,
            color: '#C5D719',
        },
        {
            name: t('杨华'),
            job: t('营运负责人'),
            education: '',
            url: HuaYang,
            color: '#94F33E',
        },
    ]

    const Member = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                {members.map((item: any, index: number) => (
                    <div key={index} className={styles.teamItem} style={{ backgroundImage: `url(${item.url})` }}>
                        <div className={styles.teamInfoCard}>
                            <div className={styles.name}>{item.name}</div>
                            {item.education && <div className={styles.job}>{item.education}</div>}
                            <div className={styles.job}>{item.job}</div>
                            <div className={styles.bottom}>
                                <div className={styles.bottomBar} style={{ background: item.color }} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={styles.page}>
            <div className={styles.companyBg}>
                <div className={styles.title}>{t('深思明辨，百药可成')}</div>
            </div>
            <div className={styles.introduction}>
                <div className={styles.height100}></div>
                <div className={`${styles.introductionTitle}`}>{t('公司简介')}</div>
                <div className={`${styles.introductionContent}`}>
                    {t(
                        `北京英飞智药科技有限公司是一家以创新药物为目标，以人工智能为驱动的初创公司。英飞智药致力于打造全球最硬核的AI药物设计系统伙伴，专注于发展应用于真实世界的药物设计技术，拥有业界领先的First-in-Class候选药开发成功率，平均测试5个分子即产生1个高活性分子。英飞智药基于自主知识产权的AI新药研发平台“智药大脑”和AI靶标发现平台“TopTargets” ，致力于高效批量开发原始创新药物，同时也为医药企业的新药研发提供硬核、先进和实用的软件产品、技术服务和解决方案。`
                    )}
                </div>
                <div className={`${styles.slogan}`}>
                    <div className={styles.sloganItem}>
                        <img src={mission} />
                        <div className={styles.sloganBg}>
                            <div className={styles.sloganTitle}>{t('使命')}</div>
                            <div className={styles.sloganDesc}>
                                {t('不断突破药物研发新边界，患者的需求就是我们的责任')}
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.sloganItem} ${styles.sloganItemRight}`}>
                        <img src={vision} />
                        <div className={styles.sloganBg}>
                            <div className={styles.sloganTitle}>{t('愿景')}</div>
                            <div className={styles.sloganDesc}>
                                {t('以人工智能药物设计技术革新药物研发流程，引领中国和世界新药研发的跨越式发展')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.milepost}>
                <div className={styles.height70}></div>
                <div className={`${styles.milepostTitle}`}>{t('里程碑事迹')}</div>
                <div className={`${styles.milepostProcessWrapper}`}>
                    <div className={styles.milepostProcess}>
                        <div className={`${styles.milepostItem} ${styles.date201903}`}>
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2019.06')}</div>
                                <div className={styles.milepostDateDesc}>{t('正式运营')}</div>
                            </div>
                        </div>
                        <div className={`${styles.milepostItem} ${styles.date2020}`}>
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2020.10')}</div>
                                <div className={styles.milepostDateDesc}>{t('PharmaMind内测版v1-v2完成')}</div>
                            </div>
                        </div>
                        <div
                            className={`${styles.milepostItem} ${styles.date2021}`}
                            style={{ top: i18n.language === lngs['en-US'].value && width < 576 ? '-40PX' : '0' }}
                        >
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2021.06')}</div>
                                <div className={styles.milepostDateDesc}>{t('苏州子公司成立')}</div>
                                <div className={styles.milepostDateDesc}>
                                    {t('布局自研/合作研究新药研发管线6条，与数家药企达成重要合作')}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.milepostItem} ${styles.date202110}`}>
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2021.10')}</div>
                                <div className={styles.milepostDateDesc}>{t('获得国家高新企业认证')}</div>
                            </div>
                        </div>
                        <div
                            className={`${styles.milepostItem} ${styles.date202112}`}
                            style={{ top: i18n.language === lngs['en-US'].value && width < 576 ? '-74PX' : '0' }}
                        >
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2021.12')}</div>
                                <div className={styles.milepostDateDesc}>{t('东升杯国际创业大赛总决赛二等奖')}</div>
                                <div className={styles.milepostDateDesc}>{t('“姑苏创业领军人才”项目立项')}</div>
                                <div className={styles.milepostDateDesc}>{t('TopTargets内测版V1完成')}</div>
                            </div>
                        </div>
                        <div className={`${styles.milepostItem} ${styles.date202201}`}>
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2022.01')}</div>
                                <div className={styles.milepostDateDesc}>
                                    {t(
                                        '获得中关村国际前沿科技创新大赛人工智能领域第一名，布局自研/合作研究新药研发管线总数10条'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.milepostItem} ${styles.date202203}`}>
                            <div className={`${styles.milepostDate}`}>
                                <div>{t('2022.03')}</div>
                                <div className={styles.milepostDateDesc}>
                                    {t('PharmaMind v3.0 MolMiner正式开始对外发布与推广，管线IIP001C进入Pre-PCC阶段')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.team}>
                <div className={styles.height70}></div>
                <div className={`${styles.teamTitle}`}>{t('团队成员')}</div>
                <div className={`${styles.teamMembers}`}>
                    <Member />
                </div>
            </div>
            <div className={styles.certificate}>
                <div className={styles.height100}></div>
                <div className={`${styles.certificateTitle}`}>{t('荣誉证书')}</div>
                <div className={`${styles.certificateList}`}>
                    <div className={styles.certificateItem}>
                        {images.map((item: any, index: number) => (
                            <img
                                key={index}
                                style={item.style}
                                src={item.url}
                                onClick={item.onClick}
                                onMouseEnter={width < 786 ? () => {} : item.onMouseEnter}
                                onMouseOut={width < 786 ? () => {} : item.onMouseOut}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company
