import React, { useEffect, useState } from 'react'
import styles from './home.module.less'
import { useTranslation } from 'react-i18next'
import targetIdentification from '~/images/home/target-identification.png'
import hitIdentification from '~/images/home/hit-identification.png'
import leadOptimization from '~/images/home/lead-optimization.png'
import preclinicalCandidateCompounds from '~/images/home/preclinical-candidate-compounds.png'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '@/hooks/windowSize/Index'

const SupportsPage: React.FC<any> = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleClick = (id) => {
        navigate('/technical')
        setTimeout(() => {
            console.log(id)
            document.getElementById(id).scrollIntoView()
        }, 100)
    }

    const { width } = useWindowSize()

    const [isMobile, setIsMobile] = useState(false)

    const serviceList = [
        {
            id: 'targetIdentification',
            title: 'Target Identification/Selection',
            desc: [
                '基于英飞智药独特的AI创新靶点发现平台TopTargets，利用AI技术挑选出最有价值和最容易成药的靶点，进行FIC或BIC药物开发。',
            ],
            isHover: true,
            imgUrl: targetIdentification,
        },
        {
            id: 'hitIdentification',
            title: 'Hit Identification',
            desc: [
                '利用AIDD/CADD融合的(超高通量)虚拟筛选、从头药物分子设计、（基于结构）的药物分子生成、多靶点药物分子设计、针对IDP的药物分子设计等技术，高效设计和发现苗头/先导化合物。',
            ],
            isHover: false,
            imgUrl: hitIdentification,
        },
        {
            id: 'leadOptimization',
            title: 'Lead Optimization',
            desc: [
                '对于苗头分子/先导分子结构进行优化改造，提高生物活性，改善药代动力学性质，降低毒性，获得安全有效，具有后续开发价值的候选药物；',
                '基于靶点结构进行优化，在提高结合能力的基础上，同时评估化合物的成药性和可合成性，缩短实际反馈所需的资源和时间，快速获得更有可能成药的先导/候选化合物分子。',
            ],
            isHover: false,
            imgUrl: leadOptimization,
        },
        {
            id: 'preclinicalCandidateCompounds',
            title: 'Preclinical Candidate Compounds',
            desc: [
                '结构决定性质，化合物的性质在前期如果能够早一步考虑，将在开发阶段事半功倍；',
                '使用AI模型，对于一个化合物的多维度性质进行评价，包括药理活性、药代动力学性质和各种毒性和安全性，可上市性潜力等进行预测和优化，极大提高药物研发的成功率。',
            ],
            isHover: false,
            imgUrl: preclinicalCandidateCompounds,
        },
    ]

    useEffect(() => {
        setIsMobile(width < 786)
    }, [width])

    const [services, setServices] = useState(serviceList)

    const handleMouseEnter = (index: number) => {
        setServices((services) => {
            const newServices = [...services]
            newServices.forEach((item, i) => {
                item.isHover = false
            })
            newServices[index].isHover = true
            return newServices
        })
    }

    const handleMouseLeave = () => {
        console.log('leave')
        // setServices((services) => {
        //     const newServices = [...services]
        //     newServices.forEach((item, i) => {
        //         item.isHover = false
        //     })
        //     return newServices
        // })
    }

    return (
        <div className={styles.supports}>
            <div className={styles.height190}></div>
            <div className={styles.supportsInfo}>
                <div className={`${styles.supportsTitle} wow fadeInUp`}>{t('技术服务')}</div>
                <div className={`${styles.supportsDesc} wow fadeInUp`}>
                    {t(
                        '智药大脑（PharmaMind）平台打造国际最硬核的AI药物设计系统伙伴，专注于发展应用于真实世界的药物设计技术，为新药研发提供高精度的AI技术服务和解决方案，可为早期药物研发提供一站式服务，包括且不限于：靶点发现和选择、苗头化合物和先导化合物发现、先导化合物优化、PCC和Pre-IND品种开发等。'
                    )}
                </div>
                <div className={`${styles.techList} wow fadeInUp`}>
                    {services.map((item, index) =>
                        isMobile ? (
                            <div
                                key={index}
                                className={`${styles.techItem}`}
                                onClick={() => {
                                    handleClick(item.id)
                                }}
                            >
                                <div className={`${styles.techTitle}`}>{t(item.title)}</div>
                                <div className={styles.techDesc}>
                                    {item.desc.map((desc, index) => (
                                        <span key={index}>{t(desc)}</span>
                                    ))}
                                </div>
                                <img className={styles.techItemImg} src={item.imgUrl} />
                            </div>
                        ) : (
                            <div
                                key={index}
                                className={`${styles.techItem} ${
                                    item.isHover ? `${styles.scaleAnim} ${styles.scaleMd} ${styles.scaleMargin} ${styles.techItemHover}` : ''
                                }`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                                onClick={() => {
                                    handleClick(item.id)
                                }}
                            >
                                <div className={`${styles.techTitle}`} style={{ color: item.isHover ? '#fff': '' }}>{t(item.title)}</div>
                                <div className={styles.techDesc} style={{ color: item.isHover ? '#fff': '' }}>
                                    {item.isHover && item.desc.map((desc, index) => <span key={index}>{t(desc)}</span>)}
                                </div>
                                <img className={item.isHover? styles.techItemImgHover: styles.techItemImg} src={item.imgUrl} />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default SupportsPage
